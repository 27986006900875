import React, { useState, useMemo, useEffect, useRef } from "react";
import { Card, AddAccountCard, AddCredit } from "..";
import "./UserBox.styled";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import { getCredit } from "../../utils/calculations";
import { useUser } from "../../hooks";
import { PaymentHistory,
     PopoverCustom,
     UserBadgesContainer
} from "./UserBox.styled";
import { withRouter } from 'react-router-dom'
import { TransferCredit } from "../TransferCredit/TransferCredit";
import { getUserName } from "../../utils/users";
import { useTranslation } from "react-i18next";

import { EyeIcon, PlusCircleIcon, AlertTriangleIcon, XCircleIcon, EyeOffIcon, SlidersHorizontal, ChevronDownIcon, Play, StopCircle, Bookmark, Info, ChevronUpIcon } from "lucide-react"

/* Add helper function for contrast color */
function getContrastColor(hexColor) {
  let color = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
  if (color.length === 3) {
    color = color.split('').map(c => c + c).join('');
  }
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? "#000000" : "#ffffff";
}

const UserBox = ({ history, user, enabled, isAdmin, modifyUser, isBoxCollapsed, onToggleBox, globalSuggestedTags, selected, onSelectChange }) => {
  const { t } = useTranslation()
  const { disableEmail, enableEmail, setReadyStatus, addPayment, transferCredit, updateTags, addCorrection } = useUser()
  const [showDisabledAccounts, setShowDisabledAccounts] = useState(false);
  const [show, setShow] = useState(false);
  const [showTransferCredit, setShowTransferCredit] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingCorrectionModal, setShowBillingCorrectionModal] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [newTagColor, setNewTagColor] = useState("#ffffff");
  const [tagError, setTagError] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentPercentage, setPaymentPercentage] = useState("40");
  const [paymentMethod, setPaymentMethod] = useState("tipsterpage");
  const [paymentReference, setPaymentReference] = useState("");
  const [correctionAmount, setCorrectionAmount] = useState("");
  const [correctionDescription, setCorrectionDescription] = useState("");
  const [correctionReference, setCorrectionReference] = useState("");

  const handleToggleBox = () => {
    if (onToggleBox) {
      onToggleBox(user._id.email);
    }
  };

  const handleWatchBets = () => history.push(`/${user._id.email}/bets`);

  const handleAddBookie = () => history.push(`/bookies/${user._id.email}`);

  const isBookieEnabled = (bookie) => {
    const { enabled } = bookie;
    return enabled;
  };

  const changeAccountStatus = () => {
    enabled
      ? disableEmail(user._id.email).then(() => history.push('/home/bot'))
      : enableEmail(user._id.email).then(() => history.push('/home/bot'))
  }

  const changeAccountReadyStatus = () => setReadyStatus(user._id.email, !user?.isReady).then(() => history.push('/home/bot'))

  const toggleDisabledAccounts = () => setShowDisabledAccounts(!showDisabledAccounts);

  const getEnabledAccounts = () => user?.bookies.filter((bookie) => isBookieEnabled(bookie));

  const getDisabledAccounts = () => user?.bookies.filter((bookie) => !isBookieEnabled(bookie));

  const getCreditForUser = () => getCredit(user);

  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalAccounts = useMemo(() => user?.bookies, [user]);

  const visibleAccounts = useMemo(() => showDisabledAccounts ? totalAccounts : getEnabledAccounts(), [user, showDisabledAccounts]);

  const handleAddNewTag = () => {
    const trimmedTag = newTag.trim();
    if (!trimmedTag) {
      setTagError("Tag cannot be empty.");
      return;
    }
    if (trimmedTag.length > 100) {
      setTagError("Tag cannot exceed 100 characters.");
      return;
    }
    if (tagList.some(t => t.name === trimmedTag)) {
      setTagError("Duplicate tag.");
      return;
    }
    const newTagObj = { name: trimmedTag, color: newTagColor };
    setTagList([...tagList, newTagObj]);
    setNewTag("");
    setNewTagColor("#ffffff");
    setTagError("");
  };

  const handleSaveTags = async () => {
    try {
      await updateTags(user._id.email, { tags: tagList });
      setShowTagModal(false);
    } catch (err) {
      console.error('Failed to update tags', err); // eslint-disable-line no-console
    }
  };

  const handleAddPayment = async (values) => {
    try {
      const formattedValues = {
        amount: parseFloat(values.amount) || 0,
        rate: parseInt(values.rate, 10) || 40,
        method: String(values.method || 'tipsterpage'),
        reference: String(values.reference || ''),
        type: 'credit'
      };

      // Basic validation
      if (formattedValues.amount <= 0) {
        return; // Could add error handling here
      }

      await addPayment(user._id.email, formattedValues);
      setShowPaymentModal(false);
      
      // Reset form with defaults
      setPaymentAmount("");
      setPaymentPercentage("40");
      setPaymentMethod("tipsterpage");
      setPaymentReference("");
    } catch (err) {
      console.error('Failed to add payment', err); // eslint-disable-line no-console
    }
  };

  const handleAddBillingCorrection = async () => {
    try {
      const formattedValues = {
        amount: parseFloat(correctionAmount) || 0
      };

      // Basic validation
      if (formattedValues.amount === 0) {
        return; // Could add error handling here
      }

      await addCorrection(user._id.email, formattedValues);
      setShowBillingCorrectionModal(false);
      
      // Reset form
      setCorrectionAmount("");
      setCorrectionDescription("");
      setCorrectionReference("");
    } catch (err) {
      console.error('Failed to add billing correction', err); // eslint-disable-line no-console
    }
  };

  return (
    <div className="bg-[#192619] shadow-lg rounded-xl relative">
      <div className="border-b border-[#ABCDDF]/20 p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row justify-start sm:justify-start items-start sm:items-center gap-2 mb-2">
        {user?.enabled && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold bg-green-100 text-green-800 border-[3.5px] border-green-800 border-solid">
              {t("enabled")}
            </span>
          )}
          { ((isAdmin && user?.isReady) || (user?.tags?.length > 0)) && (
            <UserBadgesContainer>
              {isAdmin && user?.isReady && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold text-green-100 bg-green-800">
                  {t("ready")}
            </span>
          )}
          {user?.tags && user.tags.map((tag, index) => {
            const tagObj = typeof tag === "string" ? { name: tag, color: "#ffffff" } : tag;
            return (
              <span
                key={index}
                style={{ backgroundColor: tagObj.color, color: getContrastColor(tagObj.color) }}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold group relative hover:pr-6"
              >
                {tagObj.name}
                {isAdmin && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const updatedTags = (user.tags || []).filter((_, i) => i !== index);
                      updateTags(user._id.email, { tags: updatedTags });
                    }}
                    className="absolute right-1 opacity-0 group-hover:opacity-100 transition-all duration-200 hover:text-red-500"
                  >
                    <XCircleIcon size={14} />
                  </button>
                )}
              </span>
            );
          })}
          </UserBadgesContainer>
          )}
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="flex items-center space-x-2">
            <h2 className="text-white text-xl sm:text-2xl font-bold">{getUserName(user)}</h2>
          </div>
          <div className="flex flex-wrap gap-2 justify-start sm:justify-end">
            <button className="bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base" onClick={handleWatchBets}>
              <EyeIcon size={16} />
              <span>{t("watchBets")}</span>
            </button>
            <button className="bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base" onClick={handleAddBookie}>
              <PlusCircleIcon size={16} />
              <span>{t("addAccount")}</span>
            </button>
            {isAdmin && onSelectChange && (
              <button
                onClick={onSelectChange}
                className={`bg-[#3A4C39] hover:bg-[#4A5C49] px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base ${selected ? 'text-[#E8FC8B]' : 'text-[#ABCDDF]'}`}
              >
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={e => e.stopPropagation()}
                  className="h-4 w-4 rounded border-gray-300 text-[#E8FC8B] focus:ring-[#E8FC8B]"
                />
              </button>
            )}
            <button 
              onClick={handleToggleBox}
              className="bg-[#3A4C39] text-[#ABCDDF] hover:bg-[#4A5C49] hover:text-[#E8FC8B] px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base"
            >
              {isBoxCollapsed ? <ChevronDownIcon size={16} /> : <ChevronUpIcon size={16} />}
            </button>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setShowDropdown(showDropdown ? null : -1)}
                className="bg-[#3A4C39] text-[#ABCDDF] hover:bg-[#4A5C49] hover:text-[#E8FC8B] px-3 py-1 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 text-sm sm:text-base"
              >
                <SlidersHorizontal className="h-4 w-4 mr-1 sm:mr-2" />
                <span className="hidden sm:inline">{t("actions")}</span>
                <ChevronDownIcon className="h-4 w-4 ml-1 sm:ml-2" />
              </button>
              {showDropdown === -1 && (
                <div className="absolute max-sm:right-[-100px] sm:left-0 mt-2 w-48 sm:w-56 rounded-md shadow-lg bg-[#2A3C29] ring-1 ring-black ring-opacity-5 z-50">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                      onClick={() => toggleDisabledAccounts()}
                      className="flex items-center w-full px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] transition-colors duration-200 hover:text-[#E8FC8B]"
                    >
                      {showDisabledAccounts ? (
                        <EyeIcon className="inline-block mr-2 h-4 w-4" />
                      ) : (
                        <EyeOffIcon className="inline-block mr-2 h-4 w-4" />
                      )}
                      {showDisabledAccounts ? <span className="flex-grow text-left">{t("showEnabledAccounts")}</span> : <span className="flex-grow text-left">{t("showDisabledAccounts")}</span>}
                    </button>
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => changeAccountStatus()}
                      >
                        {enabled ? <StopCircle className="inline-block mr-2 h-4 w-4" /> : <Play className="inline-block mr-2 h-4 w-4" />}
                        {enabled ? t("disableAccount") : t("enableAccount")}
                      </button>
                    )}
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => changeAccountReadyStatus()}
                      >
                        <Bookmark className="inline-block mr-2 h-4 w-4" />
                        {user?.isReady ? t("setNotReady") : t("setReady")}
                      </button>
                    )}
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => {
                          setShowTagModal(true);
                          setTagList((user.tags || []).map(tag => typeof tag === "string" ? { name: tag, color: "#ffffff" } : tag));
                          setNewTag("");
                          setNewTagColor("#ffffff");
                          setTagError("");
                        }}
                      >
                        <Info className="inline-block mr-2 h-4 w-4" />
                        {t("manageTags")}
                      </button>
                    )}
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => setShowPaymentModal(true)}
                      >
                        <PlusCircleIcon className="inline-block mr-2 h-4 w-4" />
                        {t("addPayment")}
                      </button>
                    )}
                    {isAdmin && (
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-[#ABCDDF] hover:bg-[#3A4C39] hover:text-[#E8FC8B]"
                        onClick={() => setShowBillingCorrectionModal(true)}
                      >
                        <Info className="inline-block mr-2 h-4 w-4" />
                        {t("billingCorrections")}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-white/80 text-xs sm:text-sm mt-2 flex items-center gap-1">
          <span className="font-semibold">{t("profit").toUpperCase()}:</span> {Math.round(((user?.billing || {}).profit || 0) * 100) / 100}€
          <span>|</span>
          <span className="font-semibold">{getCreditForUser().amount < 0 ? t("credit").toUpperCase() : t("toPay").toUpperCase()}:</span> {Math.abs(getCreditForUser().amount)}€
          {getCreditForUser().payments.length > 0 && (
            <OverlayTrigger
              trigger="click"
              key="left"
              placement="bottom"
              overlay={
                <PopoverCustom id={`PopoverCustom-positioned-left`}>
                  <PopoverCustom.Title as="h3">
                    {t("paymentInfo")}
                  </PopoverCustom.Title>
                  <PopoverCustom.Content>
                    <p>
                      <strong>{t("rateOverProfit")}:</strong>{" "}
                      {getCreditForUser().rate}%
                    </p>
                    <a>
                      <strong>{t("paymentHistory")}</strong>
                    </a>
                    {getCreditForUser().payments
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((payment, index) => (
                        <PaymentHistory key={index}>
                          {t("paid")} <b>{payment.amount.toFixed(2)}€</b> el{" "}
                          <b>
                            {new Date(
                              payment.timestamp
                            ).toLocaleDateString()}
                          </b>
                        </PaymentHistory>
                      ))}
                  </PopoverCustom.Content>
                </PopoverCustom>
              }
            >
              <button className="ml-1 text-[#ABCDDF] bg-[#4A5C49] hover:text-[#E8FC8B] p-2 rounded-md">
                <Info className="h-4 w-4" />
              </button>
            </OverlayTrigger>
          )}
        </div>
      </div>
      {!isBoxCollapsed && visibleAccounts?.length > 0 && (
        <div className="grid gap-4 p-4 sm:grid-cols-2 lg:grid-cols-3">
          {visibleAccounts.map((account, index) => (
            <Card account={account}
              key={`${account.name}-${index}`}
              index={index}
              history={history}
              email={user._id.email}
              name={account.name}
              enabled={account.enabled}
              bookie={account}
              isAdmin={isAdmin}
              status={account.status}
              username={account.username}
              balance={account.balance}
            />
          ))}
        </div>
      )}
      <div className="border-t border-[#ABCDDF]/20 p-4">
        <p className="text-[#758b77] text-sm">
          {t("totalAccounts")}: {totalAccounts.length} | {t("visibleAccounts")}: {visibleAccounts.length} | {t("lastUpdate")}: {new Date(user?.updatedOn).toLocaleString()}
        </p>
      </div>
      {showTagModal && (
        <Modal show={showTagModal} onHide={() => setShowTagModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("manageTags")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="mb-3">
                <label className="block text-sm font-medium mb-1 text-white">{t("currentTags")}</label>
                <div className="flex flex-wrap">
                  {tagList.map((tag, index) => (
                    <span
                      key={index}
                      style={{ backgroundColor: tag.color, color: getContrastColor(tag.color) }}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold mr-2 mb-2"
                    >
                      {tag.name}
                      <button onClick={() => setTagList(tagList.filter((_, i) => i !== index))} className="ml-1 text-red-500">x</button>
                    </span>
                  ))}
                </div>
              </div>
              <div className="space-y-4">
                <label className="block text-sm font-medium mb-1 text-white">{t("addNewTag")}</label>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-grow">
                      <input
                        type="text"
                        value={newTag}
                        onChange={e => setNewTag(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleAddNewTag();
                          }
                        }}
                        placeholder={t("enterTagName")}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <label className="text-sm text-white">{t("color")}:</label>
                      <div className="relative">
                        <input
                          type="color"
                          value={newTagColor}
                          onChange={e => setNewTagColor(e.target.value)}
                          className="w-10 h-10 p-1 rounded cursor-pointer"
                          title={t("selectColor")}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {/* Preview section */}
                  {newTag && (
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-white">{t("preview")}:</span>
                      <span
                        style={{ backgroundColor: newTagColor, color: getContrastColor(newTagColor) }}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold"
                      >
                        {newTag}
                      </span>
                    </div>
                  )}
                  
                  <div className="flex justify-end">
                    <button
                      onClick={handleAddNewTag}
                      className="px-4 py-2 bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 rounded-md flex items-center space-x-2"
                    >
                      <PlusCircleIcon size={16} />
                      <span>{t("addTag")}</span>
                    </button>
                  </div>
                </div>
                {tagError && (
                  <p className="mt-2 text-sm text-red-400">
                    {tagError}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium mb-1 text-white">{t("suggestedTags")}</label>
                <div className="flex flex-wrap">
                  {globalSuggestedTags.map((tag, index) => (
                    <span
                      key={index}
                      style={{ backgroundColor: tag.color, color: getContrastColor(tag.color), cursor: "pointer" }}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold mr-2 mb-2"
                      onClick={() => {
                        if (!tagList.some(t => t.name === tag.name)) {
                          setTagList([...tagList, tag]);
                        }
                      }}
                    >
                      {tag.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={() => setShowTagModal(false)}>{t("cancel")}</button>
            <button className="px-4 py-2 bg-green-500 text-white rounded" onClick={handleSaveTags}>{t("save")}</button>
          </Modal.Footer>
        </Modal>
      )}
      {showPaymentModal && (
        <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t("addCreditPayment")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col lg:flex-row gap-6">
              {/* Payment History Section */}
              <div className="w-full lg:w-1/2 lg:border-r border-[#ABCDDF]/20 lg:pr-6">
                <h3 className="text-lg font-semibold text-white mb-4">{t("paymentHistory")}</h3>
                <div className="space-y-4 max-h-[200px] lg:max-h-[400px] overflow-y-auto">
                  {getCreditForUser().payments.length > 0 ? (
                    getCreditForUser().payments
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((payment, index) => (
                        <div key={index} className="bg-[#2A3C29] p-4 rounded-lg">
                          <div className="flex justify-between items-start mb-2">
                            <span className="text-[#E8FC8B] font-semibold">{payment.amount.toFixed(2)}€</span>
                            <span className="text-[#ABCDDF] text-sm">
                              {new Date(payment.timestamp).toLocaleDateString()}
                            </span>
                          </div>
                          {payment.method && (
                            <div className="text-[#ABCDDF] text-sm">
                              {t("paymentMethod")}: {t(payment.method)}
                            </div>
                          )}
                          {payment.reference && (
                            <div className="text-[#ABCDDF] text-sm">
                              {t("reference")}: {payment.reference}
                            </div>
                          )}
                          <div className="text-[#ABCDDF] text-sm">
                            {t("rateOverProfit")}: {payment.rate}%
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="text-[#ABCDDF] text-center py-4">{t("noPaymentHistory")}</div>
                  )}
                </div>
              </div>

              {/* New Payment Form Section */}
              <div className="w-full lg:w-1/2">
                <h3 className="text-lg font-semibold text-white mb-4">{t("newPayment")}</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1 text-white">{t("amountPaid")}</label>
                    <div className="relative">
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="0.00"
                        value={paymentAmount}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                        onChange={(e) => setPaymentAmount(e.target.value)}
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">€</span>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1 text-white">{t("profitPercentage")}</label>
                    <div className="relative">
                      <input
                        type="number"
                        min="0"
                        max="100"
                        placeholder="30"
                        value={paymentPercentage}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                        onChange={(e) => setPaymentPercentage(e.target.value)}
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1 text-white">{t("paymentMethod")}</label>
                    <select
                      className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option value="" disabled>{t("selectPaymentMethod")}</option>
                      <option value="tipsterpage">Tipsterpage</option>
                      <option value="crypto">Crypto</option>
                      <option value="revolut">Revolut</option>
                      <option value="bizum">Bizum</option>
                      <option value="cash">Cash</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1 text-white">{t("reference")} ({t("optional")})</label>
                    <input
                      type="text"
                      placeholder={t("enterReference")}
                      value={paymentReference}
                      className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                      onChange={(e) => setPaymentReference(e.target.value)}
                    />
                  </div>

                  <div className="flex justify-end pt-4">
                    <button
                      onClick={() => {
                        handleAddPayment({
                          amount: paymentAmount,
                          rate: paymentPercentage,
                          method: paymentMethod,
                          reference: paymentReference
                        });
                      }}
                      disabled={!paymentAmount || !paymentPercentage || !paymentMethod}
                      className="px-4 py-2 bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 disabled:opacity-50 disabled:cursor-not-allowed rounded-md flex items-center space-x-2"
                    >
                      <PlusCircleIcon size={16} />
                      <span>{t("add")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showBillingCorrectionModal && (
        <Modal show={showBillingCorrectionModal} onHide={() => setShowBillingCorrectionModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t("billingCorrections")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col lg:flex-row gap-6">
              {/* Corrections History Section */}
              <div className="w-full lg:w-1/2 lg:border-r border-[#ABCDDF]/20 lg:pr-6">
                <h3 className="text-lg font-semibold text-white mb-4">{t("correctionsHistory")}</h3>
                <div className="space-y-4 max-h-[200px] lg:max-h-[400px] overflow-y-auto">
                  {getCreditForUser().corrections.length > 0 ? (
                    getCreditForUser().corrections
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((correction, index) => (
                        <div key={index} className="bg-[#2A3C29] p-4 rounded-lg">
                          <div className="flex justify-between items-start mb-2">
                            <span className={`font-semibold ${correction.amount < 0 ? 'text-green-400' : 'text-red-400'}`}>
                              {correction.amount >= 0 ? '+' : ''}{correction.amount.toFixed(2)}€
                            </span>
                            <span className="text-[#ABCDDF] text-sm">
                              {new Date(correction.timestamp).toLocaleDateString()}
                            </span>
                          </div>
                          {correction.description && (
                            <div className="text-[#ABCDDF] text-sm">
                              {t("description")}: {correction.description}
                            </div>
                          )}
                          {correction.reference && (
                            <div className="text-[#ABCDDF] text-sm">
                              {t("reference")}: {correction.reference}
                            </div>
                          )}
                        </div>
                      ))
                  ) : (
                    <div className="text-[#ABCDDF] text-center py-4">{t("noCorrectionsHistory")}</div>
                  )}
                </div>
              </div>

              {/* New Correction Form Section */}
              <div className="w-full lg:w-1/2">
                <h3 className="text-lg font-semibold text-white mb-4">{t("newCorrection")}</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1 text-white">{t("amount")}</label>
                    <div className="relative">
                      <input
                        type="number"
                        step="0.01"
                        placeholder="0.00"
                        value={correctionAmount}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900"
                        onChange={(e) => setCorrectionAmount(e.target.value)}
                      />
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">€</span>
                    </div>
                  </div>

                  <div className="flex justify-end pt-4">
                    <button
                      onClick={handleAddBillingCorrection}
                      disabled={!correctionAmount}
                      className="px-4 py-2 bg-[#E8FC8B] text-[#1B2D1A] hover:bg-[#E8FC8B]/90 disabled:opacity-50 disabled:cursor-not-allowed rounded-md flex items-center space-x-2"
                    >
                      <PlusCircleIcon size={16} />
                      <span>{t("add")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default withRouter(UserBox)